export const state = () => ({
  project: null
})

export const getters = {}

export const mutations = {
  UPDATE_PROJECT (state, project) {
    state.project = project
  }
}

export const actions = {
  GetProject ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/projects/' + data.projectID)
        .then((response) => {
          commit('UPDATE_PROJECT', response.data.result)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
}

export const modules = {}
