import { API_ANALYSIS_PATH, API_CLIENT_PATH } from '~/utils/consts/white-cat/consts'

export const state = () => ({
  analysis: [],
  analysisPerClient: [],
  analysisSearch: null,
  lastestAnalyses: [],
  analysesMeta: {
    currentPage: 1,
    sizePerPage: 10,
    totalItems: 0
  }
})

export const getters = {}

export const mutations = {
  UPDATE_ANALYSIS (state, analysis) {
    state.analysis = analysis
  },

  UPDATE_ANALYSIS_SEARCH (state, analysis) {
    state.analysisSearch = analysis
  },

  UPDATE_ANALYSIS_META (state, meta) {
    state.analysesMeta = meta
  },

  UPDATE_LASTEST_ANALYSES (state, analyses) {
    state.lastestAnalyses = analyses
  },

  ADD_ANALYSIS (state, analysis) {
    state.analysis = [...state.analysis, analysis]
  },

  ADD_ANALYSIS_PER_CLIENT (state, client) {
    const index = state.analysisPerClient.findIndex(item => item.clientId === client.clientId)
    if (index !== -1) {
      // ClientID exists, update projects array
      state.analysisPerClient[index].projects = client.projects
    } else {
      // ClientID doesn't exist, add a new item
      state.analysisPerClient.push(client)
    }
  }
}

export const actions = {
  GetAnalaysisForClient ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_CLIENT_PATH}/${data.id}/analysis`)
        .then((response) => {
          commit('ADD_ANALYSIS_PER_CLIENT', {
            clientId: data.id,
            projects: response.data.result
          })
          resolve()
        })
        .catch((e) => {
          reject(e.response.error)
        })
    })
  },

  GetLastestAnalyses ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_CLIENT_PATH}/listing`, {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('UPDATE_LASTEST_ANALYSES', response.data.result.items)
          commit('UPDATE_ANALYSIS_META', response.data.result.meta)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  GetAnalaysisSearch ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_CLIENT_PATH, {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('white-cat/clients/UPDATE_CLIENTS_SEARCH', response.data.result.items, { root: true })
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  CreateAnalaysis ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post(API_ANALYSIS_PATH, {
        ...data
      })
        .then((response) => {
          // commit('ADD_ANALYSIS', response.data.result)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }

}

export const modules = {}
