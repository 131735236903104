

import Logo from '~/components/Common/Logo/Logo'

export default {
  name: 'LayoutAuth',

  components: {
    Logo
  },

  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      link: [
        { rel: 'canonical', href: process.env.APP_URL + this.$route.path }
      ]
    }
  }
}
