
import Dialog from '~/components/ElementUI/Dialog/Dialog'
import MessageAlert from '~/mixins/MessageAlert'
import MessageError from '~/mixins/Forms/MessageError'
import FormRules from '~/mixins/Forms/FormRules'
import Loading from '~/mixins/Loading'

export default {
  name: 'SegmentAddDialog',

  components: {
    Dialog
  },

  mixins: [
    MessageAlert,
    MessageError,
    FormRules,
    Loading
  ],

  data () {
    return {
      form: {
        name: ''
      }
    }
  },

  methods: {
    submitForm () {
      this.$refs.segmentAddForm.validate(async (valid) => {
        if (valid) {
          this.Loading()

          await this.$store.dispatch('segments/AddSegment', {
            data: {
              name: this.form.name,
              filters: {
                ...this.$route.query
              }
            }
          })
            .then((response) => {
              this.MessageAlert({ type: 'success', message: 'Segment został dodany.' })

              this.form.name = ''

              this.$nuxt.$emit('segmentAddDialog', false)
            })
            .catch((error) => {
              this.MessageError(error)
            })

          this.Loading()
        } else {
          return false
        }
      })
    }
  }
}
