import Vue from 'vue'

import Emit from '@/directives/emit'
import Intersect from '@/directives/intersect'
import Scrollto from '@/directives/scrollto'

export default () => {
  Vue.directive('emit', Emit)
  Vue.directive('intersect', Intersect)
  Vue.directive('scrollto', Scrollto)
}
