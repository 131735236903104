
import Dialog from '~/components/ElementUI/Dialog/Dialog'
import Loading from '~/mixins/Loading'
import MessageAlert from '~/mixins/MessageAlert'
import MessageError from '~/mixins/Forms/MessageError'
import FormRules from '~/mixins/Forms/FormRules'

export default {
  name: 'ClientCreateDialog',

  components: {
    Dialog
  },

  mixins: [
    Loading,
    MessageAlert,
    MessageError,
    FormRules
  ],

  data: () => {
    return {
      form: {
        name: ''
      }
    }
  },

  methods: {
    submitForm () {
      this.$refs.clientCreateForm.validate(async (valid) => {
        if (valid) {
          this.Loading()

          await this.$store.dispatch('clients/createClient', {
            data: {
              name: this.form.name
            }
          })
            .then(() => {
              this.MessageAlert({
                type: 'success',
                message: 'Klient został pomyślnie dodany.',
                showClose: true
              })

              this.resetForm()

              this.$nuxt.$emit('clientCreateDialog', false)
            })
            .catch((error) => {
              this.MessageError(error.response.data.error)
            })

          this.Loading()
        } else {
          return false
        }
      })
    },

    resetForm () {
      this.form.name = ''
    }
  }
}
