
import Logo from '~/components/Common/Logo/Logo'

export default {
  components: {
    Logo
  },

  props: [
    'error'
  ]
}
