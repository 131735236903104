export default {
  methods: {
    ScrollTo (element, event) {
      event.preventDefault()

      const el = document.querySelector('#' + element)
      if (el) {
        const width = window.innerWidth
        let headerOffset = 110
        if (width > 768 && width <= 1280) {
          headerOffset = 110 * (width / 1280)
        } else if (width <= 768) {
          headerOffset = 90
        }
        const elementPosition = el.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }
    }
  }
}
