
import MenuProjects from './MenuProjects.vue'
import MessageError from '~/mixins/Forms/MessageError'
import Loading from '~/mixins/Loading'
import ProjectCreateDialog from '~/components/WhiteCat/Panel/Projects/ProjectCreate/ProjectCreateDialog'

const PAGE_STEP = 6
export default {
  name: 'MenuReports',

  components: {
    ProjectCreateDialog,
    MenuProjects
  },

  mixins: [
    MessageError,
    Loading
  ],

  props: {
    clients_to_show: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      clientsPage: 1
    }
  },

  computed: {
    projectsPerClient () {
      return this.$store.state['white-cat'].projects.projectsPerClient
    },
    visibleClients () {
      return this.clients_to_show.slice(0, this.clientsPage * PAGE_STEP)
    }
  },

  async created () {
    const params = {
      'assignmentUsers[]': this.$auth.user.id
    }

    await this.getInfo(params)

    this.$nuxt.$on('updateClientsMenu', (data) => {
      this.getInfo()
    })
  },

  methods: {
    async getInfo (data) {
      this.Loading()

      await this.$store.dispatch('white-cat/clients/GetClients', {
        data
      })

      this.Loading()
    },

    loadClients () {
      if (this.clientsPage * PAGE_STEP > this.clients_to_show.length + PAGE_STEP) { return }
      this.clientsPage++
    },

    openProjectCreateDialog (client) {
      this.$nuxt.$emit('projectCreateDialogData', {
        client
      })
      this.$nuxt.$emit('projectCreateDialog', true)
    },

    async fetchProjectsByClient (clientID) {
      const projectsPerClient = this.$store.getters['white-cat/projects/getProjectsForClient'](clientID)
      if (projectsPerClient.length) { return Promise.resolve() }
      await this.$store.dispatch('white-cat/projects/GetProjectsPerClient', {
        data: {
          'page[size]': 1000000000000000,
          client: clientID
        }
      })
    }
  }
}
