export default {
  inserted (el, binding, vnode) {
    let observer = null
    if (Array.isArray(binding.value.emit)) {
      binding.value.emit.forEach((item) => {
        observer = new IntersectionObserver(
          ([e]) => {
            vnode.context.$nuxt.$emit(item, e)
          },
          {
            threshold: [0.5],
            ...binding.value.options
          }
        )

        observer.observe(el)
      })
    } else {
      observer = new IntersectionObserver(
        ([e]) => {
          vnode.context.$nuxt.$emit(binding.value.emit, e)
        },
        {
          threshold: [0.5],
          ...binding.value.options
        }
      )

      observer.observe(el)
    }
  }
}
