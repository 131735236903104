
import MenuMain from '~/components/WhiteDog/Menu/MenuMain'
import MenuReports from '~/components/WhiteDog/Menu/MenuReports'
import ClientCreateDialog from '~/components/Panel/Clients/ClientCreate/ClientCreateDialog'

export default {
  name: 'Sidebar',

  components: {
    MenuMain,
    MenuReports,
    ClientCreateDialog
  },

  props: {
    menu_active: {
      type: String,
      default: '1'
    }
  }
}
