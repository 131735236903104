
import MessageError from '~/mixins/Forms/MessageError'
import Loading from '~/mixins/Loading'
import ProjectCreateDialog from '~/components/Panel/Projects/ProjectCreate/ProjectCreateDialog'

export default {
  name: 'MenuReports',

  components: {
    ProjectCreateDialog
  },

  mixins: [
    MessageError,
    Loading
  ],

  computed: {
    clients () {
      return this.$store.state.clients.clientsMenu
    }
  },

  async created () {
    const params = {
      'assignmentUsers[]': this.$auth.user.id
    }

    await this.getInfo(params)

    this.$nuxt.$on('updateClientsMenu', (data) => {
      this.getInfo()
    })
  },

  methods: {
    async getInfo (data) {
      this.Loading()

      await this.$store.dispatch('clients/GetClientsMenu', {
        data
      })

      this.Loading()
    },

    openProjectCreateDialog (client) {
      this.$nuxt.$emit('projectCreateDialogData', {
        client
      })
      this.$nuxt.$emit('projectCreateDialog', true)
    }
  }
}
