import { API_CLIENTS_LISTING, API_CLIENTS_LISTING_SIMULATION } from '~/utils/consts/white-dog/consts'

export const state = () => ({
  clients_listing: null,
  clients_listing_simulation: null,
  meta: null
})

export const getters = {}

export const mutations = {
  UPDATE_CLIENTS_LISTING (state, clientListing) {
    state.clients_listing = clientListing
  },

  UPDATE_CLIENTS_LISTING_SIMULATION (state, clientListing) {
    state.clients_listing_simulation = clientListing
  },

  UPDATE_META (state, meta) {
    state.meta = meta
  }
}

export const actions = {
  GetClientsListing ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_CLIENTS_LISTING, {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('UPDATE_CLIENTS_LISTING', response.data.result.items)

          commit('UPDATE_META', response.data.result.meta)

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  GetClientsListingSimulation ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_CLIENTS_LISTING_SIMULATION, {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('UPDATE_CLIENTS_LISTING_SIMULATION', response.data.result.items)

          commit('UPDATE_META', response.data.result.meta)

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
}

export const modules = {}
