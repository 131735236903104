
import Dialog from '~/components/ElementUI/Dialog/Dialog'
import Loading from '~/mixins/Loading'
import MessageAlert from '~/mixins/MessageAlert'
import MessageError from '~/mixins/Forms/MessageError'
import FormRules from '~/mixins/Forms/FormRules'

export default {
  name: 'ClientCreateDialog',

  components: {
    Dialog
  },

  mixins: [
    Loading,
    MessageAlert,
    MessageError,
    FormRules
  ],

  data: () => {
    return {
      form: {
        name: ''
      }
    }
  },

  methods: {
    submitForm () {
      this.$refs.clientCreateForm.validate(async (valid) => {
        if (valid) {
          this.Loading()

          await this.$store.dispatch('white-cat/clients/CreateClient', {
            data: {
              name: this.form.name
            }
          })
            .then(async (response) => {
              this.MessageAlert({
                type: 'success',
                message: 'Klient został pomyślnie dodany.',
                showClose: true
              })

              this.resetForm()

              this.$nuxt.$emit('clientCreateDialog', false)
              await this.$store.dispatch('white-cat/projects/GetProjectsPerClient', {
                data: {
                  'page[size]': 1000000000000000,
                  client: response.data.result.id
                }
              })
              this.$nuxt.$emit('clientCreated', response.data.result)
            })
            .catch((error) => {
              this.MessageError(error.response.error)
            })

          this.Loading()
        } else {
          return false
        }
      })
    },

    resetForm () {
      this.form.name = ''
    }
  }
}
