import { API_INDUSTRY_PATH } from '~/utils/consts/white-cat/consts'

export const state = () => ({
  industries: []
})

export const getters = {}

export const mutations = {
  UPDATE_INDUSTRIES (state, industries) {
    state.industries = industries
  }
}

export const actions = {
  GetIndustries ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_INDUSTRY_PATH)
        .then((response) => {
          commit('UPDATE_INDUSTRIES', response.data.result)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
  // CreateIndustry

}

export const modules = {}
