export const state = () => ({
  client_position: null,
  all_analyzed_details: [],
  analyses_details: null
})

export const getters = {}

export const mutations = {
  UPDATE_CLIENT_POSITION (state, clientPosition) {
    state.client_position = clientPosition
  },

  CREATE_ANALYSES_DETAILS (state, analysesDetails) {
    state.analyses_details = analysesDetails
    state.all_analyzed_details = analysesDetails
  },

  UPDATE_ANALYSES_DETAILS (state, analysesDetails) {
    state.analyses_details = analysesDetails
    state.analyses_details.push()
  }
}

export const actions = {
  CreateAnalysesDetails ({ commit, rootState }) {
    commit('CREATE_ANALYSES_DETAILS', rootState.analysis.all_analyzed_details)
  },

  GetClientPosition ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/dashboard/' + data.analysisId + '/client_position', {
        params: {
          ...data.params
        }
      })
        .then((response) => {
          commit('UPDATE_CLIENT_POSITION', response.data.result.items)
          resolve()
        })
        .catch((e) => {
          if (e.response.status === 400) {
            commit('UPDATE_CLIENT_POSITION', [])

            this._vm.$message({
              type: 'error',
              duration: 5000,
              message: e.response.data.error,
              showClose: true
            })

            resolve()
          } else {
            reject(e.response.data.error)
          }
        })
    })
  }
}

export const modules = {}
