
const PAGE_STEP = 6

export default {
  name: 'MenuProjects',
  props: {
    client_id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      projectsPage: 1
    }
  },

  computed: {
    projects () {
      return this.$store.getters['white-cat/projects/getProjectsForClient'](this.client_id) || []
    },
    visibleProjects () {
      return this.projects.slice(0, this.projectsPage * PAGE_STEP)
    }
  },

  methods: {
    loadProjects () {
      if (this.projectsPage * PAGE_STEP > this.projects.length + PAGE_STEP) { return }
      this.projectsPage++
    }
  }
}
