

export default {
  name: 'LayoutDefault',

  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      link: [
        { rel: 'canonical', href: process.env.APP_URL + this.$route.path }
      ]
    }
  }
}
