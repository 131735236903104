export const state = () => ({
  segments: []
})

export const getters = {}

export const mutations = {
  UPDATE_SEGMENTS (state, segments) {
    state.segments = segments
  },

  ADD_SEGMENT (state, segment) {
    state.segments.push(segment)
  },

  REMOVE_SEGMENT (state, segment) {
    const index = state.segments.findIndex((item) => { return item.id === segment.id })

    if (index !== -1) {
      state.segments.splice(index, 1)
    }
  }
}

export const actions = {
  GetSegments ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/segments')
        .then((response) => {
          commit('UPDATE_SEGMENTS', response.data.result.items)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  AddSegment ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post('/api/segments', {
        ...data
      })
        .then((response) => {
          commit('ADD_SEGMENT', response.data.result)

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  RemoveSegment ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.delete('/api/segments/' + data.segmentID)
        .then((response) => {
          commit('REMOVE_SEGMENT', {
            id: data.segmentID
          })

          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
}

export const modules = {}
