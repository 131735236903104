import {
  API_SIMULATION_ANALYSIS,
  API_SIMULATION_OPTIMAL_SCENARIO,
  API_SIMULATION_FACTOR,
  API_SIMULATION_OWN_SCENARIO_SIMULATE,
  API_SIMULATION_OWN_SCENARIO,
  API_SIMULATION_ANALYSIS_KEYWORD
} from '~/utils/consts/white-dog/consts'

export const state = () => ({
  simulation_analysis: null,
  simulation_analysis_keyword: null,
  optimal_scenario: null,
  own_scenario: [],
  all_own_scenario: [],
  meta: null,
  simulation_factor: null
})

export const getters = {
  getScenario: state => (scenarioID) => {
    return state.all_own_scenario.filter(item => item.id === scenarioID)
  },

  getSimulationFactorItem: state => (factorName) => {
    return state.simulation_factor.filter(item => item.name === factorName)
  }
}

export const mutations = {
  UPDATE_SIMULATION_ANALYSIS (state, simulationAnalysis) {
    state.simulation_analysis = simulationAnalysis
  },

  UPDATE_SIMULATION_ANALYSIS_KEYWORD (state, simulationAnalysisKeyword) {
    state.simulation_analysis_keyword = simulationAnalysisKeyword
  },

  UPDATE_OPTIMAL_SCENARIO (state, optimalScenario) {
    state.optimal_scenario = optimalScenario
  },

  UPDATE_OWN_SCENARIO (state, ownScenario) {
    state.own_scenario = ownScenario
    state.all_own_scenario.push(ownScenario)
  },

  REFRESH_OWN_SCENARIO (state, ownScenario) {
    state.all_own_scenario = ownScenario
  },

  UPDATE_SIMULATION_FACTOR (state, simulationFactor) {
    state.simulation_factor = simulationFactor
  }
}

export const actions = {
  GetSimulationAnalysis ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_SIMULATION_ANALYSIS}/${data.id}`, {
        params: {
          ...data.params
        }
      })
        .then((response) => {
          commit('UPDATE_SIMULATION_ANALYSIS', response.data.result)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  GetSimulationAnalysisKeyword ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_SIMULATION_ANALYSIS_KEYWORD}/${data.id}`, {
        params: {
          ...data.params
        }
      })
        .then((response) => {
          commit('UPDATE_SIMULATION_ANALYSIS_KEYWORD', response.data.result)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  GetSimulationOptimalScenario ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_SIMULATION_OPTIMAL_SCENARIO}/${data.id}`)
        .then((response) => {
          commit('UPDATE_OPTIMAL_SCENARIO', response.data.result)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  GetSimulationOwnScenario ({ commit }, { data, refresh = false }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_SIMULATION_OWN_SCENARIO}/${data.id}`)
        .then((response) => {
          if (refresh) {
            commit('REFRESH_OWN_SCENARIO', response.data.result)
          } else {
            commit('UPDATE_OWN_SCENARIO', response.data.result)
          }

          resolve(response.data.result)
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  CreateSimulationOwnScenario ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`${API_SIMULATION_OWN_SCENARIO_SIMULATE}`, {
        ...data.params
      })
        .then((response) => {
          // commit('UPDATE_OWN_SCENARIO', response.data.result)

          resolve(response.data.result)
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  UpdateSimulationOwnScenario ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`${API_SIMULATION_OWN_SCENARIO}`, {
        ...data.params
      })
        .then((response) => {
          commit('UPDATE_OWN_SCENARIO', response.data.result)

          resolve(response.data.result)
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  GetSimulationFactor ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_SIMULATION_FACTOR}`, {
        params: {
          page: {
            number: 1,
            size: 50
          }
        }
      })
        .then((response) => {
          commit('UPDATE_SIMULATION_FACTOR', response.data.result.items)

          resolve(response.data.result)
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  }
}

export const modules = {}
