import { parse, formatDistance } from 'date-fns'
import { pl } from 'date-fns/locale'

export default function (date) {
  const parseDate = parse(date, 'dd.MM.yyyy HH:mm', new Date())

  return formatDistance(
    new Date(parseDate),
    new Date(),
    {
      locale: pl,
      addSuffix: true
    }
  )
}
