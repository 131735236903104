export const state = () => ({
  clientsMenu: null,
  clients: null,
  meta: null,
  client: null
})

export const getters = {}

export const mutations = {
  UPDATE_CLIENTS_MENU (state, clientsMenu) {
    state.clientsMenu = clientsMenu
  },

  ADD_CLIENTS_MENU (state, client) {
    state.clientsMenu.push(client)
  },

  ADD_PROJECT_CLIENT_MENU (state, data) {
    const index = state.clientsMenu.findIndex(x => x.id === data.client.id)
    state.clientsMenu[index].projects.push(data)
  },

  ADD_PROJECT_CLIENT (state, data) {
    if (state.client.id === data.client.id) {
      state.client.projects.push(data)
    }
  },

  UPDATE_CLIENTS (state, clients) {
    state.clients = clients
  },

  UPDATE_META (state, meta) {
    state.meta = meta
  },

  UPDATE_CLIENT (state, client) {
    state.client = client
  }
}

export const actions = {
  GetClientsMenu ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/clients', {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('UPDATE_CLIENTS_MENU', response.data.result.items)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  GetClients ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/clients', {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('UPDATE_CLIENTS', response.data.result.items)
          commit('UPDATE_META', response.data.result.meta)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  GetClient ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/clients/' + data.clientID)
        .then((response) => {
          commit('UPDATE_CLIENT', response.data.result)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  createClient ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post('/api/clients', {
        ...data
      })
        .then((response) => {
          commit('ADD_CLIENTS_MENU', response.data.result)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
}

export const modules = {}
