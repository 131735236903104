import {
  API_ESTIMATION_LENGTH,
  API_NEW_QUERIES_POPULARITY
} from '~/utils/consts/estimator-seo/consts'

export const state = () => ({
  estimation_length: [],
  queries: []
})

export const getters = {}

export const mutations = {
  UPDATE_ESTIMATION_LENGTH (state, estimationLength) {
    state.estimation_length = estimationLength
  },

  UPDATE_QUERIES (state, queries) {
    state.queries = queries
  }
}

export const actions = {
  GetEstimationLength ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_ESTIMATION_LENGTH)
        .then((response) => {
          commit('UPDATE_ESTIMATION_LENGTH', response.data.result)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  GetQueries ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_NEW_QUERIES_POPULARITY)
        .then((response) => {
          commit('UPDATE_QUERIES', response.data.result)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  }
}

export const modules = {}
