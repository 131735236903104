import { API_ANALYSIS_PATH, API_REFERENCE_PREDICTION_PATH, API_CUSTOM_PREDICTION_PATH } from '~/utils/consts/white-cat/consts'

const downloadCSV = (csvContent, filename) => {
  try {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = `${filename}.csv`
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  } catch (error) {
    console.error('Error processing CSV content:', error)
  }
}

export const state = () => ({
  analysis: null
})

export const getters = {}

export const mutations = {
  SET_ANALYSIS (state, analysis) {
    state.analysis = analysis
  }
}

export const actions = {
  GetAnalysis ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_ANALYSIS_PATH}/${data.id}`)
        .then((response) => {
          commit('SET_ANALYSIS', response.data.result)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },
  ExportReferencePrediction ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_REFERENCE_PREDICTION_PATH}/${data.id}/export`)
        .then((response) => {
          const csvData = response.data
          resolve(downloadCSV(csvData, `${data.name}-reference-prediction`))
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  ExportCustomPrediction ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_CUSTOM_PREDICTION_PATH}/${data.id}/export`)
        .then((response) => {
          const csvData = response.data
          resolve(downloadCSV(csvData, `${data.name}-custom-prediction-${data.id}`))
        })
        .catch((e) => {
          reject(e)
        })
    })
  },
  AddCustomPrediction ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('file', data.file.raw)
      formData.append('analysis', data.analysis)
      this.$axios({
        method: 'post',
        url: `${API_CUSTOM_PREDICTION_PATH}`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then((response) => {
          resolve(response.data.result.id)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
}

export const modules = {}
