export default {
  bind (el, binding, vnode) {
    el.addEventListener('click', (e) => {
      vnode.context.ScrollTo(binding.value.element, e)
    })
  },
  unbind (el) {
    el.removeEventListener('click')
  }
}
