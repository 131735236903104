import { API_PROJECT_PATH } from '~/utils/consts/white-cat/consts'

export const state = () => ({
  projects: [],
  project: null,
  projectsPerClient: []
})

export const getters = {
  getProjectsForClient: state => (clientID) => {
    const projects = state.projectsPerClient.filter(item => item.clientID === clientID)
    return !projects.length ? projects : projects[0]?.projects
  }
}

export const mutations = {
  UPDATE_PROJECTS (state, projects) {
    state.projects = projects
  },
  UPDATE_PROJECTS_PER_CLIENT (state, projectsPerClient) {
    const index = state.projectsPerClient.findIndex(item => item.clientID === projectsPerClient.clientID)

    if (index !== -1) {
      // ClientID exists, update projects array
      state.projectsPerClient[index].projects = projectsPerClient.projects
    } else {
      // ClientID doesn't exist, add a new item
      state.projectsPerClient.push({
        clientID: projectsPerClient.clientID,
        projects: projectsPerClient.projects
      })
    }
  },
  UPDATE_PROJECT (state, project) {
    state.project = project
  }
}

export const actions = {
  GetProjects ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_PROJECT_PATH, {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('UPDATE_PROJECTS', response.data.result.items)
          // commit('white-cat/clients/', data, { root: true });
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },
  GetProjectsPerClient ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_PROJECT_PATH, {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('UPDATE_PROJECTS_PER_CLIENT', {
            clientID: `${data.client}`,
            projects: response.data.result.items
          })
          // commit('white-cat/clients/', data, { root: true });
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },
  GetProject ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_PROJECT_PATH}/${data.projectID}`)
        .then((response) => {
          commit('UPDATE_PROJECT', response.data.result)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
}

export const modules = {}
