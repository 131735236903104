export const state = () => ({
  all_conclusions: [],
  conclusions: [],
  meta: null
})

export const getters = {}

export const mutations = {
  UPDATE_CONCLUSIONS (state, conclusions) {
    state.conclusions = conclusions
    state.all_conclusions.push(...conclusions)
  },

  REFRESH_CONCLUSIONS (state, conclusions) {
    state.conclusions = conclusions
    state.all_conclusions = conclusions
    state.all_conclusions.push()
  },

  UPDATE_META (state, meta) {
    state.meta = meta
  },

  UPDATE_TAG (state, data) {
    const index = state.all_conclusions.findIndex((item) => { return item.id === data.id })

    if (index !== -1) {
      state.all_conclusions[index].tags = data.item.tags
      state.all_conclusions[index].tags.push()
    }
  },

  ADD_COMMENT (state, data) {
    const index = state.all_conclusions.findIndex((item) => { return item.id === data.id })

    if (index !== -1) {
      state.all_conclusions[index].comments.push(data.comment)
    }
  },

  UPDATE_COMMENT (state, data) {
    const index = state.all_conclusions.findIndex((item) => { return item.id === data.comment.analyzedFactorId })
    const indexComment = state.all_conclusions[index].comments.findIndex((item) => { return item.id === data.comment.id })

    if (index !== -1) {
      state.all_conclusions[index].comments[indexComment] = data.comment
      state.all_conclusions[index].comments.push()
    }
  },

  REMOVE_COMMENT (state, data) {
    const index = state.all_conclusions.findIndex((item) => { return item.id === data.analysis_id })
    const indexComment = state.all_conclusions[index].comments.findIndex((item) => { return item.id === data.id })

    if (index !== -1 && indexComment !== -1) {
      state.all_conclusions[index].comments.splice(indexComment, 1)
    }
  }
}

export const actions = {
  GetConclusions ({ commit, rootState }, { data = {}, refresh = false }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/users/' + rootState.auth.user.id + '/conclusions', {
        params: {
          ...data
        }
      })
        .then((response) => {
          if (refresh) {
            commit('REFRESH_CONCLUSIONS', response.data.result.items)
          } else {
            commit('UPDATE_CONCLUSIONS', response.data.result.items)
          }

          commit('UPDATE_META', response.data.result.meta)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  AddConclusion ({ commit, rootState }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post('/api/users/' + rootState.auth.user.id + '/conclusions', {
        ...data
      })
        .then((response) => {
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  RemoveConclusion ({ commit, rootState }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.delete('/api/users/' + rootState.auth.user.id + '/conclusions/' + data.conclusionId)
        .then((response) => {
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  AddTag ({ commit, dispatch }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.patch('/api/analyzed_factors/' + data.id + '/tags', {
        name: data.name
      })
        .then((response) => {
          commit('UPDATE_TAG', {
            id: data.id,
            item: response.data.result
          })

          dispatch('filters/GetTags', {}, {
            root: true
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  RemoveTag ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.patch('/api/analyzed_factors/' + data.id + '/tags/' + data.tagId)
        .then((response) => {
          commit('UPDATE_TAG', {
            id: data.id,
            item: response.data.result
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  AddComment ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post('/api/comments', {
        content: data.comment,
        analyzedFactor: data.id
      })
        .then((response) => {
          commit('ADD_COMMENT', {
            id: data.id,
            comment: response.data.result
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  EditComment ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.put('/api/comments/' + data.comment.id, {
        content: data.content,
        analyzedFactor: data.id
      })
        .then((response) => {
          commit('UPDATE_COMMENT', {
            comment: response.data.result
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  RemoveComment ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.delete('/api/comments/' + data.id)
        .then((response) => {
          commit('REMOVE_COMMENT', {
            id: data.id,
            analysis_id: data.analysis_id
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
}

export const modules = {}
