export default {
  methods: {
    MessageAlert ({ type = 'error', message = 'Coś poszło nie tak, proszę spróbować później.', duration = 5000, offset = 20, customClass = '', showClose = false }) {
      this.$message({
        message,
        type,
        duration,
        offset,
        customClass,
        showClose
      })
    }
  }
}
