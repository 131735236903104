export default {
  bind (el, binding, vnode) {
    el.addEventListener('click', () => {
      vnode.context.$nuxt.$emit(binding.value.emit, binding.value.data)
    })
  },
  unbind (el) {
    el.removeEventListener('click', null)
  }
}
