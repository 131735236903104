import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d0868e1 = () => interopDefault(import('../pages/content-graph-manager/index.vue' /* webpackChunkName: "pages/content-graph-manager/index" */))
const _2b4361d6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _25bc8fd2 = () => interopDefault(import('../pages/estimator-seo/index.vue' /* webpackChunkName: "pages/estimator-seo/index" */))
const _8c40fa02 = () => interopDefault(import('../pages/tf-idf/index.vue' /* webpackChunkName: "pages/tf-idf/index" */))
const _491d3d39 = () => interopDefault(import('../pages/white-cat/index.vue' /* webpackChunkName: "pages/white-cat/index" */))
const _13ba3b1f = () => interopDefault(import('../pages/white-dog/index.vue' /* webpackChunkName: "pages/white-dog/index" */))
const _4dbcb4f8 = () => interopDefault(import('../pages/auth/google.vue' /* webpackChunkName: "pages/auth/google" */))
const _9d7b04b8 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _031580e8 = () => interopDefault(import('../pages/white-cat/analyses/index.vue' /* webpackChunkName: "pages/white-cat/analyses/index" */))
const _de31cc3c = () => interopDefault(import('../pages/white-dog/analysis/index.vue' /* webpackChunkName: "pages/white-dog/analysis/index" */))
const _1d4216d8 = () => interopDefault(import('../pages/white-dog/clients/index.vue' /* webpackChunkName: "pages/white-dog/clients/index" */))
const _5da0a7c1 = () => interopDefault(import('../pages/white-dog/conclusions.vue' /* webpackChunkName: "pages/white-dog/conclusions" */))
const _904c2d3e = () => interopDefault(import('../pages/white-dog/dashboard.vue' /* webpackChunkName: "pages/white-dog/dashboard" */))
const _78125734 = () => interopDefault(import('../pages/white-dog/settings.vue' /* webpackChunkName: "pages/white-dog/settings" */))
const _330ef6c8 = () => interopDefault(import('../pages/white-dog/simulations/index.vue' /* webpackChunkName: "pages/white-dog/simulations/index" */))
const _59241555 = () => interopDefault(import('../pages/white-dog/users.vue' /* webpackChunkName: "pages/white-dog/users" */))
const _eb51f1fc = () => interopDefault(import('../pages/white-cat/analyses/_clientID/index.vue' /* webpackChunkName: "pages/white-cat/analyses/_clientID/index" */))
const _7dd62cca = () => interopDefault(import('../pages/white-dog/analysis/_id.vue' /* webpackChunkName: "pages/white-dog/analysis/_id" */))
const _7397ef40 = () => interopDefault(import('../pages/white-dog/clients/_id.vue' /* webpackChunkName: "pages/white-dog/clients/_id" */))
const _30e69468 = () => interopDefault(import('../pages/white-dog/projects/_id.vue' /* webpackChunkName: "pages/white-dog/projects/_id" */))
const _9f09d812 = () => interopDefault(import('../pages/white-dog/reports/_id.vue' /* webpackChunkName: "pages/white-dog/reports/_id" */))
const _a3f41cf2 = () => interopDefault(import('../pages/white-dog/simulations/_id/index.vue' /* webpackChunkName: "pages/white-dog/simulations/_id/index" */))
const _b8c40542 = () => interopDefault(import('../pages/white-cat/analyses/_clientID/analysis/index.vue' /* webpackChunkName: "pages/white-cat/analyses/_clientID/analysis/index" */))
const _7bccd163 = () => interopDefault(import('../pages/white-cat/analyses/_clientID/analysis/_analysisID.vue' /* webpackChunkName: "pages/white-cat/analyses/_clientID/analysis/_analysisID" */))
const _371a309b = () => interopDefault(import('../pages/white-dog/simulations/_id/_simulation.vue' /* webpackChunkName: "pages/white-dog/simulations/_id/_simulation" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/content-graph-manager",
    component: _0d0868e1,
    name: "content-graph-manager___pl"
  }, {
    path: "/en",
    component: _2b4361d6,
    name: "index___en"
  }, {
    path: "/estimator-seo",
    component: _25bc8fd2,
    name: "estimator-seo___pl"
  }, {
    path: "/tf-idf",
    component: _8c40fa02,
    name: "tf-idf___pl"
  }, {
    path: "/white-cat",
    component: _491d3d39,
    name: "white-cat___pl"
  }, {
    path: "/white-dog",
    component: _13ba3b1f,
    name: "white-dog___pl"
  }, {
    path: "/auth/google",
    component: _4dbcb4f8,
    name: "auth-google___pl"
  }, {
    path: "/auth/reset-password",
    component: _9d7b04b8,
    name: "auth-reset-password___pl"
  }, {
    path: "/en/content-graph-manager",
    component: _0d0868e1,
    name: "content-graph-manager___en"
  }, {
    path: "/en/estimator-seo",
    component: _25bc8fd2,
    name: "estimator-seo___en"
  }, {
    path: "/en/tf-idf",
    component: _8c40fa02,
    name: "tf-idf___en"
  }, {
    path: "/en/white-cat",
    component: _491d3d39,
    name: "white-cat___en"
  }, {
    path: "/en/white-dog",
    component: _13ba3b1f,
    name: "white-dog___en"
  }, {
    path: "/white-cat/analyses",
    component: _031580e8,
    name: "white-cat-analyses___pl"
  }, {
    path: "/white-dog/analysis",
    component: _de31cc3c,
    name: "white-dog-analysis___pl"
  }, {
    path: "/white-dog/clients",
    component: _1d4216d8,
    name: "white-dog-clients___pl"
  }, {
    path: "/white-dog/conclusions",
    component: _5da0a7c1,
    name: "white-dog-conclusions___pl"
  }, {
    path: "/white-dog/dashboard",
    component: _904c2d3e,
    name: "white-dog-dashboard___pl"
  }, {
    path: "/white-dog/settings",
    component: _78125734,
    name: "white-dog-settings___pl"
  }, {
    path: "/white-dog/simulations",
    component: _330ef6c8,
    name: "white-dog-simulations___pl"
  }, {
    path: "/white-dog/users",
    component: _59241555,
    name: "white-dog-users___pl"
  }, {
    path: "/en/auth/google",
    component: _4dbcb4f8,
    name: "auth-google___en"
  }, {
    path: "/en/auth/reset-password",
    component: _9d7b04b8,
    name: "auth-reset-password___en"
  }, {
    path: "/en/white-cat/analyses",
    component: _031580e8,
    name: "white-cat-analyses___en"
  }, {
    path: "/en/white-dog/analysis",
    component: _de31cc3c,
    name: "white-dog-analysis___en"
  }, {
    path: "/en/white-dog/clients",
    component: _1d4216d8,
    name: "white-dog-clients___en"
  }, {
    path: "/en/white-dog/conclusions",
    component: _5da0a7c1,
    name: "white-dog-conclusions___en"
  }, {
    path: "/en/white-dog/dashboard",
    component: _904c2d3e,
    name: "white-dog-dashboard___en"
  }, {
    path: "/en/white-dog/settings",
    component: _78125734,
    name: "white-dog-settings___en"
  }, {
    path: "/en/white-dog/simulations",
    component: _330ef6c8,
    name: "white-dog-simulations___en"
  }, {
    path: "/en/white-dog/users",
    component: _59241555,
    name: "white-dog-users___en"
  }, {
    path: "/",
    component: _2b4361d6,
    name: "index___pl"
  }, {
    path: "/en/white-cat/analyses/:clientID",
    component: _eb51f1fc,
    name: "white-cat-analyses-clientID___en"
  }, {
    path: "/en/white-dog/analysis/:id",
    component: _7dd62cca,
    name: "white-dog-analysis-id___en"
  }, {
    path: "/en/white-dog/clients/:id",
    component: _7397ef40,
    name: "white-dog-clients-id___en"
  }, {
    path: "/en/white-dog/projects/:id",
    component: _30e69468,
    name: "white-dog-projects-id___en"
  }, {
    path: "/en/white-dog/reports/:id",
    component: _9f09d812,
    name: "white-dog-reports-id___en"
  }, {
    path: "/en/white-dog/simulations/:id",
    component: _a3f41cf2,
    name: "white-dog-simulations-id___en"
  }, {
    path: "/en/white-cat/analyses/:clientID/analysis",
    component: _b8c40542,
    name: "white-cat-analyses-clientID-analysis___en"
  }, {
    path: "/en/white-cat/analyses/:clientID/analysis/:analysisID",
    component: _7bccd163,
    name: "white-cat-analyses-clientID-analysis-analysisID___en"
  }, {
    path: "/en/white-dog/simulations/:id/:simulation",
    component: _371a309b,
    name: "white-dog-simulations-id-simulation___en"
  }, {
    path: "/white-cat/analyses/:clientID",
    component: _eb51f1fc,
    name: "white-cat-analyses-clientID___pl"
  }, {
    path: "/white-dog/analysis/:id",
    component: _7dd62cca,
    name: "white-dog-analysis-id___pl"
  }, {
    path: "/white-dog/clients/:id",
    component: _7397ef40,
    name: "white-dog-clients-id___pl"
  }, {
    path: "/white-dog/projects/:id",
    component: _30e69468,
    name: "white-dog-projects-id___pl"
  }, {
    path: "/white-dog/reports/:id",
    component: _9f09d812,
    name: "white-dog-reports-id___pl"
  }, {
    path: "/white-dog/simulations/:id",
    component: _a3f41cf2,
    name: "white-dog-simulations-id___pl"
  }, {
    path: "/white-cat/analyses/:clientID/analysis",
    component: _b8c40542,
    name: "white-cat-analyses-clientID-analysis___pl"
  }, {
    path: "/white-cat/analyses/:clientID/analysis/:analysisID",
    component: _7bccd163,
    name: "white-cat-analyses-clientID-analysis-analysisID___pl"
  }, {
    path: "/white-dog/simulations/:id/:simulation",
    component: _371a309b,
    name: "white-dog-simulations-id-simulation___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
