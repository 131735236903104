import { API_CLIENTS, API_CLIENTS_LISTING, API_ANALYSIS_COUNTRY } from '~/utils/consts/white-dog/consts'

export const state = () => ({
  filters: [],
  tags: [],
  industries: [],
  authors: [],
  clients: [],
  views: [],
  countries: []
})

export const getters = {}

export const mutations = {
  UPDATE_FILTERS (state, filters) {
    state.filters = filters
  },

  UPDATE_TAGS (state, tags) {
    state.tags = tags
  },

  UPDATE_INDUSTRIES (state, industries) {
    state.industries = industries
  },

  UPDATE_AUTHORS (state, authors) {
    state.authors = authors
  },

  UPDATE_CLIENTS (state, clients) {
    state.clients = clients
  },

  UPDATE_VIEWS (state, views) {
    state.views = views
  },

  UPDATE_COUNTRIES (state, countries) {
    state.countries = countries
  }
}

export const actions = {
  GetFilters ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/filters')
        .then((response) => {
          commit('UPDATE_FILTERS', response.data.result)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  GetTags ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/tags')
        .then((response) => {
          commit('UPDATE_TAGS', response.data.result)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  GetIndustries ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/lp/categories', {
        params: {
          lang: 'POL'
        }
      })
        .then((response) => {
          commit('UPDATE_INDUSTRIES', response.data.result)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  GetAuthors ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/users', {
        params: {
          'page[size]': 1000000000000000
        }
      })
        .then((response) => {
          commit('UPDATE_AUTHORS', response.data.result.items)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  GetClients ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_CLIENTS_LISTING, {
        params: {
          'page[size]': 1000000000000000
        }
      })
        .then((response) => {
          commit('UPDATE_CLIENTS', response.data.result.items)

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  GetViews ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/attributes')
        .then((response) => {
          commit('UPDATE_VIEWS', response.data.result)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  },

  GetCountries ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_ANALYSIS_COUNTRY)
        .then((response) => {
          commit('UPDATE_COUNTRIES', response.data.result.items)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  }
}

export const modules = {}
