import Vue from 'vue'

// import lang
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

// import parts of framework
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  Descriptions,
  DescriptionsItem,
  Dialog,
  Form,
  FormItem,
  Input,
  InputNumber,
  Menu,
  MenuItem,
  MenuItemGroup,
  Option,
  Pagination,
  Popconfirm,
  Popover,
  Progress,
  Radio,
  RadioButton,
  RadioGroup,
  Select,
  Submenu,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  // serices/directives
  InfiniteScroll,
  Message,
  Loading, Upload
} from 'element-ui'

// register element-ui components
Vue.component(Button.name, Button)
Vue.component(Checkbox.name, Checkbox)
Vue.component(CheckboxGroup.name, CheckboxGroup)
Vue.component(Collapse.name, Collapse)
Vue.component(CollapseItem.name, CollapseItem)
Vue.component(Descriptions.name, Descriptions)
Vue.component(DescriptionsItem.name, DescriptionsItem)
Vue.component(Dialog.name, Dialog)
Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)
Vue.component(Input.name, Input)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Menu.name, Menu)
Vue.component(MenuItem.name, MenuItem)
Vue.component(MenuItemGroup.name, MenuItemGroup)
Vue.component(Option.name, Option)
Vue.component(Pagination.name, Pagination)
Vue.component(Popconfirm.name, Popconfirm)
Vue.component(Popover.name, Popover)
Vue.component(Progress.name, Progress)
Vue.component(Radio.name, Radio)
Vue.component(RadioButton.name, RadioButton)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Select.name, Select)
Vue.component(Submenu.name, Submenu)
Vue.component(Table.name, Table)
Vue.component(TableColumn.name, TableColumn)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(Tooltip.name, Tooltip)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Upload.name, Upload)

// plugins
Vue.use(InfiniteScroll)
Vue.use(Loading.directive)

// services
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service

// configure language
locale.use(lang)
