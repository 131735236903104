import { API_ANALYSIS_VERSION } from '~/utils/consts/white-dog/consts'

export const state = () => ({
  all_analyzed_factors: [],
  analyzed_factors: null,
  all_analyzed_details: [],
  analyses_details: null,
  meta: null
})

export const getters = {}

export const mutations = {
  UPDATE_ANALYZED_FACTORS (state, analyzedFactors) {
    state.analyzed_factors = analyzedFactors
    state.all_analyzed_factors.push(...analyzedFactors)
  },

  UPDATE_ANALYSES_DETAILS (state, analysesDetails) {
    state.analyses_details = analysesDetails
    state.all_analyzed_details.push(...analysesDetails)
  },

  REFRESH_ANALYZED_FACTORS (state, analyzedFactors) {
    state.analyzed_factors = analyzedFactors
    state.all_analyzed_factors = analyzedFactors
    state.all_analyzed_factors.push()
  },

  REFRESH_ANALYSES_DETAILS (state, analysesDetails) {
    state.analyses_details = analysesDetails
    state.all_analyzed_details = analysesDetails
    state.all_analyzed_details.push()
  },

  UPDATE_META (state, meta) {
    state.meta = meta
  },

  UPDATE_TAG (state, data) {
    const index = state.all_analyzed_factors.findIndex((item) => { return item.id === data.id })

    if (index !== -1) {
      state.all_analyzed_factors[index].tags = data.item.tags
      state.all_analyzed_factors[index].tags.push()
    }
  },

  ADD_COMMENT (state, data) {
    const index = state.all_analyzed_factors.findIndex((item) => { return item.id === data.id })

    if (index !== -1) {
      state.all_analyzed_factors[index].comments.push(data.comment)
    }
  },

  UPDATE_COMMENT (state, data) {
    const index = state.all_analyzed_factors.findIndex((item) => { return item.id === data.comment.analyzedFactorId })
    const indexComment = state.all_analyzed_factors[index].comments.findIndex((item) => { return item.id === data.comment.id })

    if (index !== -1) {
      state.all_analyzed_factors[index].comments[indexComment] = data.comment
      state.all_analyzed_factors[index].comments.push()
    }
  },

  REMOVE_COMMENT (state, data) {
    const index = state.all_analyzed_factors.findIndex((item) => { return item.id === data.analysis_id })
    const indexComment = state.all_analyzed_factors[index].comments.findIndex((item) => { return item.id === data.id })

    if (index !== -1 && indexComment !== -1) {
      state.all_analyzed_factors[index].comments.splice(indexComment, 1)
    }
  }
}

export const actions = {
  GetAnalyzedFactors ({ commit }, { data, refresh = false }) {
    return new Promise((resolve, reject) => {
      this.$axios.post(`${API_ANALYSIS_VERSION}/${data.analysisVersionId}`, {
        ...data.params
      })
        .then((response) => {
          if (refresh) {
            commit('REFRESH_ANALYZED_FACTORS', response.data.slides ?? [])
          } else {
            commit('UPDATE_ANALYZED_FACTORS', response.data.slides ?? [])
          }

          // commit('UPDATE_META', response.data.result.meta)

          resolve()
        })
        .catch((e) => {
          resolve(e.response)
        })
    })
  },

  GetAnalysesDetails ({ commit }, { data, refresh = false }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/analyses_details', {
        params: {
          ...data
        }
      })
        .then((response) => {
          if (refresh) {
            commit('REFRESH_ANALYSES_DETAILS', response.data.result.items)
          } else {
            commit('UPDATE_ANALYSES_DETAILS', response.data.result.items)
          }

          commit('UPDATE_META', response.data.result.meta)

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  AssignAnalyzedFactorsToReport ({ commit, dispatch, state, $message }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post('/api/reports_analyzed_factors', {
        ...data
      })
        .then((response) => {
          resolve()
        })
        .catch((e) => {
          if (e.response.data.status === 403) {
            this._vm.$message({
              type: 'error',
              duration: 5000,
              message: 'Nie masz wystarczających praw do usunięcia analizy z raportu',
              showClose: true
            })
          }

          reject(e.response.data)
        })
    })
  },

  RemoveAnalyzedFactors ({ commit, dispatch, state, $message }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.delete('/api/reports_analyzed_factors', {
        data: {
          ...data
        }
      })
        .then(async (response) => {
          const meta = state.meta

          await dispatch('GetAnalyzedFactors', {
            data: {
              report: data.report,
              'page[number]': 1,
              'page[size]': meta.currentPage * meta.sizePerPage
            },
            refresh: true
          })
            .catch((e) => {
              reject(e.response.data.error)
            })

          meta.totalItems = meta.totalItems - 1
          commit('UPDATE_META', meta)

          resolve()
        })
        .catch((e) => {
          if (e.response.data.status === 403) {
            this._vm.$message({
              type: 'error',
              duration: 5000,
              message: 'Nie masz wystarczających praw do usunięcia analizy z raportu',
              showClose: true
            })
          }

          resolve(e.response.data.error)
        })
    })
  },

  AddTag ({ commit, dispatch }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.patch('/api/analyzed_factors/' + data.id + '/tags', {
        name: data.name
      })
        .then((response) => {
          commit('UPDATE_TAG', {
            id: data.id,
            item: response.data.result
          })

          dispatch('filters/GetTags', {}, {
            root: true
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  RemoveTag ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.patch('/api/analyzed_factors/' + data.id + '/tags/' + data.tagId)
        .then((response) => {
          commit('UPDATE_TAG', {
            id: data.id,
            item: response.data.result
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  AddComment ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post('/api/comments', {
        content: data.comment,
        analyzedFactor: data.id
      })
        .then((response) => {
          commit('ADD_COMMENT', {
            id: data.id,
            comment: response.data.result
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  EditComment ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.put('/api/comments/' + data.comment.id, {
        content: data.content,
        analyzedFactor: data.id
      })
        .then((response) => {
          commit('UPDATE_COMMENT', {
            comment: response.data.result
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  RemoveComment ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.delete('/api/comments/' + data.id)
        .then((response) => {
          commit('REMOVE_COMMENT', {
            id: data.id,
            analysis_id: data.analysis_id
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  CreateFilter ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post('/api/analyses/' + data.analysis_id + '/filter', {
        filters: data.filters
      })
        .then((response) => {
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
}

export const modules = {}
