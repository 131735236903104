
import MenuMain from '~/components/WhiteCat/Menu/MenuMain'
import MenuReports from '~/components/WhiteCat/Menu/MenuReports'
import ClientCreateDialog from '~/components/WhiteCat/Panel/Clients/ClientCreate/ClientCreateDialog'

export default {
  name: 'Sidebar',

  components: {
    MenuMain,
    MenuReports,
    ClientCreateDialog
  },
  props: {
    menu_active: {
      type: String,
      default: '1'
    }
  },

  computed: {
    clients () {
      return this.$store.state['white-cat'].clients.clients
    }
  }

}
