export const state = () => ({
  roles: [
    {
      label: 'Admin',
      value: 'ROLE_ADMIN'
    },
    {
      label: 'User',
      value: 'ROLE_USER'
    }
  ],
  filtersAdvanced: {
    isCount: [
      {
        label: 'Uwzględnij',
        value: 'include'
      },
      {
        label: 'Wyklucz',
        value: 'exclude'
      }
    ],
    matrix: [
      {
        label: 'Zapytanie',
        value: 'query'
      },
      {
        label: 'Domena',
        value: 'domain'
      },
      {
        label: 'Ranking',
        value: 'ranking'
      }
    ],
    operation: [
      {
        label: 'Zaczyna sie od',
        value: 1
      },
      {
        label: 'Konczy sie na',
        value: 2
      },
      {
        label: 'Wieksze',
        value: 3
      },
      {
        label: 'Wieksze lub rowne',
        value: 4
      },
      {
        label: 'Identyczne',
        value: 5
      },
      {
        label: 'Mniejsze',
        value: 6
      },
      {
        label: 'Mniejsze lub rowne',
        value: 7
      },
      {
        label: 'Zawiera',
        value: 8
      },
      {
        label: 'Nie zawiera',
        value: 9
      },
      {
        label: 'Różne',
        value: 10
      }
    ]
  }
})

export const getters = {}

export const mutations = {}

export const actions = {}

export const modules = {}
