

import Sidebar from '~/components/Common/Sidebar/Sidebar'
import Submenu from '~/components/WhiteDog/Menu/Submenu'

export default {
  name: 'LayoutPanel',

  components: {
    Sidebar,
    Submenu
  },

  middleware: 'auth',

  data: () => {
    return {
      isSubmenu: false
    }
  },

  head () {
    return {
      ...this.$nuxtI18nHead({ addSeoAttributes: true }),
      link: [
        { rel: 'canonical', href: process.env.APP_URL + this.$route.path }
      ]
    }
  },

  watch: {
    '$route' (newValue) {
      if (newValue.matched[0].path !== '/white-dog/analysis/:id') {
        this.isSubmenu = false
      }
    }
  }
}
