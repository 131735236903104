

export default {
  name: 'MenuMain',
  props: {
    active: {
      type: String,
      default: '1'
    }
  }
}
