export const API_CLIENTS = '/api/clients'
export const API_CLIENTS_LISTING = '/api/clients/listing-new'
export const API_CLIENTS_LISTING_SIMULATION = '/api/clients/simulation-listing'
export const API_ANALYSIS_COUNTRY = '/api/analysis-country'
export const API_ANALYSIS = '/api/analysis'
export const API_ANALYSIS_CSV = '/api/analysis/csv'
export const API_ANALYSIS_VERSION = '/api/analysis-version'
export const API_ANALYSIS_DUPLICATE = '/api/analysis/duplicate'
export const API_SIMULATION_ANALYSIS = '/api/simulation-analysis'
export const API_SIMULATION_ANALYSIS_KEYWORD = '/api/simulation-analysis-keyword'
export const API_SIMULATION_ANALYSIS_KEYWORD_SIMULATE = '/api/simulation-analysis-keyword/simulate'
export const API_SIMULATION_OPTIMAL_SCENARIO = '/api/simulation-optimal-scenario'
export const API_SIMULATION_FACTOR = '/api/simulation-factor'
export const API_SIMULATION_OWN_SCENARIO = '/api/simulation-own-scenario'
export const API_SIMULATION_OWN_SCENARIO_SIMULATE = '/api/simulation-own-scenario/simulate'
