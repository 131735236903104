
export default {
  props: {
    emit: {
      type: String,
      default: 'dialog_active'
    },
    title: {
      type: String,
      default: ''
    },
    footer: {
      type: Boolean,
      default: false
    },
    custom_class: {
      type: String,
      default: ''
    },
    show_close: {
      type: Boolean,
      default: true
    },
    close_modal: {
      type: Boolean,
      default: true
    },
    modal: {
      type: Boolean,
      default: true
    },
    destroy: {
      type: Boolean,
      default: false
    },
    open: {
      type: Function,
      default: () => {}
    },
    close_dialog: {
      type: Function,
      default: () => {}
    }
  },

  data: () => ({
    dialogActive: false
  }),

  created () {
    this.$nuxt.$on(this.emit, (data) => {
      this.dialogActive = data
    })
  }
}
