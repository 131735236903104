export const state = () => ({
  all_reports: [],
  reports: null,
  meta: null,
  report: null
})

export const getters = {}

export const mutations = {
  UPDATE_REPORTS (state, reports) {
    state.reports = reports
    state.all_reports.push(...reports)
  },

  UPDATE_META (state, meta) {
    state.meta = meta
  },

  UPDATE_REPORT (state, report) {
    state.report = report
  }
}

export const actions = {
  GetReports ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/reports', {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('UPDATE_REPORTS', response.data.result.items)
          commit('UPDATE_META', response.data.result.meta)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  GetReport ({ commit, dispatch }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/reports/' + data.reportID)
        .then((response) => {
          commit('UPDATE_REPORT', response.data.result)

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  AddReport ({ commit, rootState, dispatch }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.post('/api/reports', {
        ...data
      })
        .then(async (response) => {
          await dispatch('GetReports', {
            data: {
              project: rootState.projects.project.id
            }
          })
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
}

export const modules = {}
