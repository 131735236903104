export const state = () => ({
  users: null,
  meta: null
})

export const getters = {}

export const mutations = {
  UPDATE_USERS (state, users) {
    state.users = users
  },

  UPDATE_META (state, meta) {
    state.meta = meta
  },

  UPDATE_USER (state, user) {
    const index = state.users.findIndex(x => x.id === user.id)

    if (index !== -1) {
      state.users[index] = user
      state.users.push()
    }
  }
}

export const actions = {
  GetUsers ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('/api/users', {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('UPDATE_USERS', response.data.result.items)
          commit('UPDATE_META', response.data.result.meta)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
}

export const modules = {}
