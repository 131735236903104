import { API_CLIENTS } from '~/utils/consts/white-dog/consts'

export const state = () => ({
  analysis_clients: [],
  analysis_client: [],
  analysis_clients_simulation: [],
  analysis_client_simulation: []
})

export const getters = {
  getProjectsForClient: state => (clientID) => {
    return state.analysis_clients.filter(item => item.clientId === clientID)
  },

  getProjectsForClientSimulation: state => (clientID) => {
    return state.analysis_clients_simulation.filter(item => item.clientId === clientID)
  }
}

export const mutations = {
  UPDATE_ANALYSIS_CLIENTS (state, { analysisClient, clientId }) {
    state.analysis_clients.push({
      clientId,
      projects: analysisClient
    })
  },

  UPDATE_ANALYSIS_CLIENT (state, analysisClient) {
    state.analysis_client = analysisClient
  },

  UPDATE_ANALYSIS_CLIENTS_SIMULATION (state, { analysisClient, clientId }) {
    state.analysis_clients.push({
      clientId,
      projects: analysisClient
    })
  },

  UPDATE_ANALYSIS_CLIENT_SIMULATION (state, analysisClient) {
    state.analysis_client = analysisClient
  }
}

export const actions = {
  GetAnalysisClient ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_CLIENTS}/${data.id}/analysis`)
        .then((response) => {
          commit('UPDATE_ANALYSIS_CLIENTS', {
            analysisClient: response.data.result,
            clientId: data.id
          })
          commit('UPDATE_ANALYSIS_CLIENT', {
            ...response.data.result
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  },

  GetAnalysisClientSimulation ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`${API_CLIENTS}/${data.id}/simulation-analysis`)
        .then((response) => {
          commit('UPDATE_ANALYSIS_CLIENTS_SIMULATION', {
            analysisClient: response.data.result,
            clientId: data.id
          })
          commit('UPDATE_ANALYSIS_CLIENT_SIMULATION', {
            ...response.data.result
          })

          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
}

export const modules = {}
