
export default {
  name: 'Logout',

  methods: {
    logout () {
      this.$auth.logout()

      this.$auth.$storage.removeUniversal('user')
    }
  }
}
