
import Logout from '~/components/Auth/Logout/Logout'
import IsAdmin from '~/mixins/Roles/IsAdmin'

export default {
  name: 'MenuOther',

  components: {
    Logout
  },

  mixins: [
    IsAdmin
  ],

  props: {
    active: {
      type: String,
      default: '1'
    }
  }
}
