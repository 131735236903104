
import Dialog from '~/components/ElementUI/Dialog/Dialog'
import Loading from '~/mixins/Loading'
import MessageAlert from '~/mixins/MessageAlert'
import MessageError from '~/mixins/Forms/MessageError'
import FormRules from '~/mixins/Forms/FormRules'

export default {
  name: 'ProjectCreateDialog',

  components: {
    Dialog
  },

  mixins: [
    Loading,
    MessageAlert,
    MessageError,
    FormRules
  ],

  data: () => {
    return {
      form: {
        name: '',
        goal: ''
      },
      client: {}
    }
  },

  created () {
    this.$nuxt.$on('projectCreateDialogData', (data) => {
      this.client = data.client
    })
  },

  methods: {
    submitForm () {
      this.$refs.projectCreateForm.validate(async (valid) => {
        if (valid) {
          this.Loading()

          await this.$axios.post('/api/projects', {
            name: this.form.name,
            goal: this.form.goal,
            client: this.client.id
          })
            .then((response) => {
              this.$store.commit('clients/ADD_PROJECT_CLIENT_MENU', response.data.result)

              this.$store.dispatch('clients/GetClients', {
                data: {
                  'page[size]': 1000000000000000
                }
              })

              this.resetForm()

              this.MessageAlert({ type: 'success', message: 'Projekt został pomyślnie dodany.' })

              this.$nuxt.$emit('projectCreateDialog', false)
            })
            .catch((error) => {
              this.MessageError(error.response.data.error)
            })

          this.Loading()
        } else {
          return false
        }
      })
    },

    resetForm () {
      this.form.name = ''
      this.form.goal = ''
    }
  }
}
