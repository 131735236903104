

import SegmentAddDialog from '~/components/WhiteDog/Menu/Segments/SegmentAdd/SegmentAddDialog'
import Loading from '~/mixins/Loading'

export default {
  name: 'Submenu',

  components: {
    SegmentAddDialog
  },

  mixins: [
    Loading
  ],

  props: {
    active: {
      type: String,
      default: '0'
    }
  },

  data: () => {
    return {
      activeSubmenu: true
    }
  },

  computed: {
    segments () {
      return this.$store.state.segments.segments
    }
  },

  methods: {
    async selectedSegment (item) {
      this.Loading()

      await this.$router.replace({
        query: {
          ...this.$route.query,
          segment: item.name
        }
      })
        .catch((e) => {

        })

      this.Loading()
    },

    async removeSegment (item) {
      this.Loading()

      await this.$store.dispatch('segments/RemoveSegment', {
        data: {
          segmentID: item.id
        }
      })

      this.Loading()
    }
  }
}
