export default {
  data () {
    const validatePassword = (rule, value, callback) => {
      const regex = /^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
      if (!value.match(regex)) {
        callback(new Error('Hasło powinno co najmniej zawierać: 8 znaków, 1 dużą literę, 1 cyfrę.'))
      } else {
        callback()
      }
    }

    const validateRePassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('Podane hasła nie są takie same.'))
      } else {
        callback()
      }
    }

    return {
      rules: {
        name: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' },
          { type: 'email', message: 'Podaj poprawny adres email.', trigger: 'blur' }
        ],
        currentPassword: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' }
        ],
        repassword: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' },
          { validator: validateRePassword, trigger: 'blur' }
        ],
        require: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        goal: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        comment: [
          { required: true, message: 'Wprowadź zawartość komentarza.', trigger: 'blur' }
        ],
        description: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        client: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        project: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        frequency: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        industry: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        report: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        domain: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        budget: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        campaignPeriod: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        targetGroup: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        campaignLaunchMonth: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        googleAnalyticsViewId: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        goalNumber: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        country: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        language: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        connection: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' },
          { type: 'number', message: 'Podaj liczbę.', trigger: 'blur' }
        ],
        estimation: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        popularity: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        'commercial.movement': [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        'content.movement': [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        'home.movement': [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        technical_optimization: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        building_optimization: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ],
        content_optimization: [
          { required: true, message: 'To pole jest wymagane.', trigger: 'blur' }
        ]
      }
    }
  }
}
