import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { RefreshScheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/",
    "logout": "/",
    "home": "/white-dog/analysis",
    "callback": "/login"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/"
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "local"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new RefreshScheme($auth, {
  "token": {
    "property": "result.token",
    "maxAge": 2505600,
    "global": true,
    "type": "Bearer"
  },
  "refreshToken": {
    "property": "result.refresh_token",
    "data": "refresh_token",
    "maxAge": 2592000
  },
  "user": {
    "property": "result.user",
    "autoFetch": true
  },
  "endpoints": {
    "login": {
      "url": "/api/login_check",
      "method": "post"
    },
    "refresh": {
      "url": "/api/token/refresh",
      "method": "post"
    },
    "user": {
      "url": "/api/auth/user",
      "method": "get"
    },
    "logout": false
  },
  "name": "local"
}))

  // google
  $auth.registerStrategy('google', new RefreshScheme($auth, {
  "token": {
    "property": "result.token",
    "maxAge": 3600,
    "global": true,
    "type": "Bearer"
  },
  "refreshToken": {
    "property": "result.refresh_token",
    "data": "refresh_token",
    "maxAge": 2592000
  },
  "user": {
    "property": "result.user",
    "autoFetch": true
  },
  "endpoints": {
    "authorization": "https://accounts.google.com/o/oauth2/auth",
    "userInfo": "https://www.googleapis.com/oauth2/v3/userinfo",
    "login": {
      "url": "/api/oauth2",
      "method": "post"
    },
    "refresh": {
      "url": "/api/token/refresh",
      "method": "post"
    },
    "user": {
      "url": "/api/auth/user",
      "method": "get"
    },
    "logout": false
  },
  "name": "google",
  "scope": [
    "openid",
    "profile",
    "email"
  ]
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
