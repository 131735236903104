import {
  API_LANGUAGE
} from '~/utils/consts/tf-idf/consts'

export const state = () => ({
  language: []
})

export const getters = {}

export const mutations = {
  UPDATE_LANGUAGE (state, language) {
    state.language = language
  }
}

export const actions = {
  GetLanguage ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_LANGUAGE)
        .then((response) => {
          commit('UPDATE_LANGUAGE', response.data.result)

          resolve()
        })
        .catch((e) => {
          resolve(e.response.data.error)
        })
    })
  }
}

export const modules = {}
