export default {
  methods: {
    MessageError ({ error, type = 'error', message = 'Coś poszło nie tak, proszę spróbować później.', duration = 5000, offset = 20, customClass = '', showClose = true }) {
      if (error) {
        if (typeof error === 'object') {
          Object.values(error).forEach(item => (
            setTimeout(() => {
              this.$message({
                message: item,
                type,
                duration,
                offset,
                customClass,
                showClose
              })
            }, 100)
          ))
        } else {
          this.$message({
            message: error,
            type,
            duration,
            offset,
            customClass,
            showClose
          })
        }
      } else {
        this.$message({
          message,
          type,
          duration,
          offset,
          customClass,
          showClose
        })
      }
    }
  }
}
