
import IsAdmin from '~/mixins/Roles/IsAdmin'

export default {
  name: 'MenuMain',

  mixins: [
    IsAdmin
  ],

  props: {
    active: {
      type: String,
      default: '1'
    }
  }
}
