
import WhiteDogMenu from '~/components/WhiteDog/Menu/Menu'
import WhiteCatMenu from '~/components/WhiteCat/Menu/Menu'
import MenuOther from '~/components/Common/Menu/MenuOther'

export default {
  name: 'Sidebar',

  components: {
    WhiteDogMenu,
    WhiteCatMenu,
    MenuOther
  },

  props: {
    menu_active: {
      type: String,
      default: '1'
    }
  },

  data: () => {
    return {
      projects: [
        {
          slug: 'white-dog',
          name: 'White Dog'
        },
        {
          slug: 'content-graph-manager',
          name: 'Content Graph Manager'
        },
        {
          slug: 'white-cat',
          name: 'White Cat'
        },
        {
          slug: 'tf-idf',
          name: 'TF-IDF'
        },
        {
          slug: 'estimator-seo',
          name: 'Estymator SEO'
        }
      ],
      currentProject: {
        slug: '',
        name: 'Wybierz projekt'
      }
    }
  },

  created () {
    const path = this.$route.path

    if (/white-dog/.test(path)) {
      this.currentProject = this.projects[0]
    } else if (/content-graph-manager/.test(path)) {
      this.currentProject = this.projects[1]
    } else if (/white-cat/.test(path)) {
      this.currentProject = this.projects[2]
    } else if (/tf-idf/.test(path)) {
      this.currentProject = this.projects[3]
    } else if (/estimator-seo/.test(path)) {
      this.currentProject = this.projects[4]
    }
  },

  methods: {
    changeProject (data) {
      this.$router.push('/' + data.slug)
      this.currentProject = data
    }
  }
}
