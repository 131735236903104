import { API_GOAL_PATH } from '~/utils/consts/white-cat/consts'

export const state = () => ({
  goals: []
})

export const getters = {}

export const mutations = {
  UPDATE_GOALS (state, goals) {
    state.goals = goals
  }
}

export const actions = {
  GetGoals ({ commit }, { data }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(API_GOAL_PATH, {
        params: {
          ...data
        }
      })
        .then((response) => {
          commit('UPDATE_GOALS', response.data.result)
          resolve()
        })
        .catch((e) => {
          reject(e.response.data.error)
        })
    })
  }
  // CreateGoal

}

export const modules = {}
