
import Dialog from '~/components/ElementUI/Dialog/Dialog'
import Loading from '~/mixins/Loading'
import MessageAlert from '~/mixins/MessageAlert'
import MessageError from '~/mixins/Forms/MessageError'
import FormRules from '~/mixins/Forms/FormRules'
import { API_PROJECT_PATH } from '~/utils/consts/white-cat/consts'

export default {
  name: 'ProjectCreateDialog',

  components: {
    Dialog
  },

  mixins: [
    Loading,
    MessageAlert,
    MessageError,
    FormRules
  ],

  data: () => {
    return {
      form: {
        name: '',
        goal: ''
      },
      client: {}
    }
  },

  created () {
    this.$nuxt.$on('projectCreateDialogData', (data) => {
      this.client = data.client
    })
  },

  methods: {
    submitForm () {
      this.$refs.projectCreateForm.validate(async (valid) => {
        if (valid) {
          this.Loading()

          await this.$axios.post(API_PROJECT_PATH, {
            name: this.form.name,
            goal: this.form.goal,
            client: this.client.id
          })
            .then(async (response) => {
              this.resetForm()

              this.MessageAlert({ type: 'success', message: 'Projekt został pomyślnie dodany.' })

              this.$nuxt.$emit('projectCreateDialog', false)

              await this.$store.dispatch('white-cat/projects/GetProjectsPerClient', {
                data: {
                  'page[size]': 1000000000000000,
                  client: this.client.id
                }
              })
              this.$nuxt.$emit('projectCreated', response.data.result)
            })
            .catch((error) => {
              this.MessageError(error.response.error)
            })

          this.Loading()
        } else {
          return false
        }
      })
    },

    resetForm () {
      this.form.name = ''
      this.form.goal = ''
    }
  }
}
